// 3-cols (or 3-cols / 2-rows) list on desktop ; slider on mobile
.category-block {
  padding: 4rem; 

  @include at-most-lg {
    padding: 3.2rem; 
  }
  @include at-most-md {
    padding: 3.2rem 0;
  }
  & + .category-block {
    border-top: .1rem solid $line;
  }
  &--title {
    @include at-most-md {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }
  }
  &--link {
    margin-top: 8.8rem;

    @include at-most-md {
      margin-left: 1.6rem;
      margin-top: 8rem;
    }
    @include at-least-md {
      float: right;
    }
  }
  &--slider {
    margin-top: 2rem;
      
    @include at-most-md {
      margin-top: 1.6rem;
      padding-left: 1.6rem;

      &:not(.slick-slider) {
        padding-right: 8rem;
      }
    }
    @include at-least-md {
      display: flex;
      flex-wrap: wrap;
      margin-right: -.8rem;
      margin-left: -.8rem;

      & > * {
        flex: 0 1 33.3333% !important;
        width: 33.3333% !important;
        padding-right: .8rem;
        padding-left: .8rem;
      }
    }
    @include at-least-lg {
      margin-right: -1.6rem;
      margin-left: -1.6rem;

      & > * {
        padding-right: 1.6rem;
        padding-left: 1.6rem;
      }
    }
    .slick-slide {
      position: relative;
      font-size: 0;

      &:only-child {
        margin-left: -1.2rem; // offset slides that find themselves alone after a City filter
      }
    }
    & > .slick-list {
      @include at-most-md {
        padding: 0 4rem 0 0 !important;
      }
      & > .slick-track {
        & > .slick-slide {
          padding-right: 1.6rem;
        }
      }
    }
  }
}
