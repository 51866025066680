.form-logo {
  margin-bottom: 4.8rem;
  text-align: center;

  @include at-most-md {
    margin-bottom: 4rem;
  }
}

.form-item {
	display: flex;
  flex-direction: column;
  
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}
.form-grid {
  @include at-least-sm {
    display: flex;
    justify-content: space-between;

    .form-item {
      width: 50%;

      &:first-child {
        padding-right: 1rem;
      }
      &:last-child {
        padding-left: 1rem;
      }
    }
  }
  @include at-most-sm {
    .form-item {
      &:last-child {
        margin-bottom: 2.5rem;
      }
    }
  }
}
.form-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  
  &.single-centered {
    justify-content: center;
  }
}

.form-label {
  margin-bottom: .8rem;
  color: $grey;
  font-size: 1rem;
  text-transform: uppercase;
}
.form-input {
  padding: .7rem 1.6rem .3rem;
  border: .1rem solid #DFDFDF;
  font-size: 1.2rem;
  line-height: 1rem;
  transition: all .3s;

  &[disabled] {
    background-color: rgba(199, 199, 200, .4);
    color: rgba($deepblue, .5);
  }
  &:focus {
    border-color: $deepblue;
    outline: 0 none;
  }
  &.is-invalid {
    border-color: $red;
  }
	@include at-most-sm {
    padding: .4rem 1.6rem .1rem;
		font-size: 1.6rem;
	}
}
textarea.form-input {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  line-height: 1.6rem;
}
select.form-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 3.2rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M16 9l-3.647 3.647L8.707 9 8 9.707l4.353 4.354 4.353-4.354z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23DFDFDF' d='M-269-4H32v32h-301z'/%3E%3Cuse fill='%231E1B31' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat right .8rem center;
  background-size: 2.4rem 2.4rem;
  padding-right: 4rem;
  line-height: 1.3;

  &:disabled {
    opacity: 1;
  }
  @include at-most-sm {
    height: 3.5rem;
  }
}

.form-error,
.form-success,
.form-help {
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;

  &:not(:first-child) {
    margin-top: 1.6rem;
  }
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}
.form-error {
	color: $red;

  & + .form-help {
    margin-top: 0;
  }
}
.form-success {
  color: $green;

  & + .form-help {
    margin-top: 0;
  }
}
.form-help {
  color: $grey;
}

.activation-code {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 28rem;
  max-width: 100%;

  @include at-most-sm {
    width: 100%;
  }
  .form-input,
  .activate-code {
    background-color: #fff;
    border: .1rem solid #6D8AEA;
    border-radius: .2rem;
  }
  .form-input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    height: 4rem;
    min-width: 0;
    padding: .8rem 1.5rem .2rem 2rem;
    border-right: 0 none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.4rem;
    letter-spacing: .044rem;
    line-height: 1.6rem;
    text-transform: uppercase;

    &::-webkit-input-placeholder {
      opacity: .3;
      color: rgba($blue, .3);
    }
    &:-ms-input-placeholder {
      opacity: .3;
      color: rgba($blue, .3);
    }
    &::placeholder {
      opacity: .3;
      color: rgba($blue, .3);
    }
    &.is-invalid {
      border-color: $red;

      &::-webkit-input-placeholder {
        opacity: .3;
        color: rgba($red, .3);
      }
      &:-ms-input-placeholder {
        opacity: .3;
        color: rgba($red, .3);
      }
      &::placeholder {
        opacity: .3;
        color: rgba($red, .3);
      }
      & + .activate-code {
        border-color: $red;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M8.102 16L16 7.983 8.102 0 7.15.963l6.366 6.435H0v1.239h13.48l-6.331 6.4z'/%3E%3C/defs%3E%3Cuse fill='%23E00F00' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/svg%3E");
        }
      }
    }
  }
  .activate-code {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border-left: 0 none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -.1rem;

    &::after {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M8.102 16L16 7.983 8.102 0 7.15.963l6.366 6.435H0v1.239h13.48l-6.331 6.4z'/%3E%3C/defs%3E%3Cuse fill='%236D8AEA' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/svg%3E") no-repeat;
      content: "";
    }
  }
}
