button {
  &[type=button]:not(:disabled),
  &[type=reset]:not(:disabled),
  &[type=submit]:not(:disabled),
  &:not(:disabled) {
    cursor: pointer;
  }
}
.btn-unstyled {
  -webkit-appearance: none;
  background-color: transparent;
  padding: 0;
  border: 0 none;
}
.btn {
  -webkit-appearance: none;
  background-color: #fff;
  display: inline-block;
  width: auto;
  padding: 1.4rem 2.4rem .9rem;
  border: .1rem solid $deepblue;
  color: $deepblue;
  font-family: 'Brut Grotesque Regular', sans-serif;
  font-size: 1rem;
  letter-spacing: .05rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  transition-duration: .3s;
  transition-property: color, background-color;

  &.primary {
    background-color: $deepblue;
    border-color: $deepblue;
    color: #fff;

    &:hover:not(:disabled) {
      background-color: rgba($deepblue, .9);
    }
    &.ghost {
      border-color: $deepblue;
  
      &:hover:not(:disabled) {
        background-color: rgba($deepblue, .1);
      }
    }
  }
  &.secondary {
    background-color: #fff;
    border-color: #fff;
    color: $deepblue;

    &:hover:not(:disabled) {
      background-color: rgba(#fff, .9);
    }
    &.ghost {
      background-color: transparent;
      color: #fff;
  
      &:hover:not(:disabled) {
        background-color: rgba(#fff, .1);
      }
      &.download {
        padding-left: 3.6rem;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M16 17v1H9v-1h7zM13 6v5.793l3.146-3.146.707.707-4.354 4.354-4.353-4.354.707-.707L12 11.793V6h1z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat left .8rem center;
        background-size: 2.4rem 2.4rem;
      }
    }
  }
  &.btn-100 {
    width: 100%;
  }
}
a.btn {
  text-decoration: none;
}
.link-btn {
  text-decoration: underline;
}
.arrow-btn {
  @extend .btn-unstyled;
  display: inline-flex;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: none;

  @include at-least-md {
    font-size: 1.8rem;
  }
  &.arrow-bwd,
  &.arrow-fwd {
    &::before,
    &::after {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: -.2rem;
    }
  }
  &.arrow-bwd {
    &::before {
      margin-right: .8rem;
      transform: scaleX(-1);
      content: "";
    }
  }
  &.arrow-fwd {
    &::after {
      margin-left: .8rem;
      content: "";
    }
  }
  &.primary {
    color: $deepblue;

    &::before,
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M8.102 16L16 7.983 8.102 0 7.15.963l6.366 6.435H0v1.239h13.48l-6.331 6.4z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/svg%3E");
    }
  }
  &.secondary {
    color: #fff;

    &::before,
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M8.102 16L16 7.983 8.102 0 7.15.963l6.366 6.435H0v1.239h13.48l-6.331 6.4z'/%3E%3C/defs%3E%3Cuse fill='%23fff' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/svg%3E");
    }
  }
  &.small {
    font-size: 1.2rem;

    &::before,
    &::after {
      background-size: 1.2rem 1.2rem;
    }
  }
}
.close-btn {
  opacity: .5;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.021 6l-5.657 5.656L6.706 6 6 6.707l5.656 5.657L6 18.02l.706.708 5.658-5.657 5.657 5.657.706-.708-5.656-5.656 5.656-5.657z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat;
}
.more-btn,
.less-btn {
  width: 2.4rem;
  height: 2.4rem;
  background-repeat: no-repeat;
}
.more-btn {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M12 4v8H4v1h8v8h1v-8h8v-1h-8V4z'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // plus--large.svg
}
.less-btn {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M6 12v1h13v-1z'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // minus--large.svg
}

.btn-container {
  display: flex;
  text-align: center;

  &.vertical {
    align-items: center;
    flex-flow: column wrap;

    .btn {
      & + .btn {
        margin-top: 1.6rem;
      }
    }
  }
  &.horizontal {
    justify-content: center;
    
    .btn {
      & + .btn {
        margin-left: 1.6rem;
      }
    }
  }
}

@media (pointer: coarse) {
  .tap-expand-before,
  .tap-expand-after {
    position: relative;
  }
  .tap-expand-before::before,
  .tap-expand-after::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4.8rem;
    height: 4.8rem;
    content: "";
    transform: translate(-50%,-50%);
  }
}
