.custom-select {
  position: relative;

  &--trigger {
    width: 100%;
    display: flex;
    text-align: left;
    white-space: nowrap;

    &::after {
      flex-shrink: 0;
      position: relative;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M9.712 6L9 6.702l5.653 5.729L9.158 18l.712.702 6.189-6.271z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' transform='rotate(90 12.53 12.351)' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // chevron-down.svg
      margin-top: -.2rem;
      margin-left: .4rem;
      transition: transform .2s ease;
      content: "";
    }
    &.active {
      border-color: #fff;

      &::after {
        transform: scaleY(-1);
      }
    }
  }
  &--dropdown {
    z-index: 40;
    position: absolute;
    width: 100%;
    background-color: #fff;

    &--item {
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}
