.static-page {
  &--body {
    padding: 5.6rem 12.5% 3.2rem;

    @include at-most-lg {
      padding-right: 3.2rem;
      padding-left: 3.2rem;
    }
    @include at-most-md {
      padding-right: 1.6rem;
      padding-bottom: 1.6rem;
      padding-left: 1.6rem;
    }
  }
  &--lvl1-title {
    font-family: 'Peclers', serif;
    font-size: 4.6rem;
    line-height: 5.4rem;
    text-align: center;

    @include at-most-md {
      font-size: 3.4rem;
      line-height: 4rem;
    }
  }
  &--lvl3-title,
  h2,
  &--lvl4-title,
  h4 {
    font-family: 'Brut Grotesque Medium', sans-serif;
  }
  &--lvl2-title,
  h2 {
    margin-top: 4.8rem;
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    line-height: 3rem;
  }
  &--lvl3-title,
  h3 {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  &--lvl4-title,
  h4 {
    margin-bottom: .8rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  &--content {
    margin-top: 5.6rem;
    font-size: 1.4rem;
    line-height: 2rem;

    p {
      margin: 1.6rem 0;
    }
    ul,
    ol {
      margin: 2.4rem 0;
      padding-left: 2.4rem;
    }
    li {
      b {
        line-height: 2.4rem;
      }
      & + li {
        margin-top: 1.6rem;
      }
    }
    a {
      color: $red;
      text-decoration: underline;

      &:hover {
        color: darken($red, 10%);
      }
    }
  }
}
.agents-page {
  .static-page--sub-title {
    font-family: 'Peclers', serif;
  }
}
