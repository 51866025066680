.landing-page {
  position: relative;
  display: flex;
	width: 100%;
  height: 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @media (orientation: portrait) {
    background-position-y: bottom;
    background-image: url( ../../images/backgrounds/landing-page--mobile.jpg);
  }
	@media (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2), (orientation: portrait) and (min-resolution: 192dpi) {
		background-image: url(../../images/backgrounds/landing-page--mobile@2x.jpg);
  }
	@media (orientation: landscape) {
    background-position-y: top;
		background-image: url(../../images/backgrounds/landing-page--desktop.jpg);
	}
	@media (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2), (orientation: landscape) and (min-resolution: 192dpi) {
		background-image: url(../../images/backgrounds/landing-page--desktop@2x.jpg);
	}
}

.page {
  overflow: hidden;
  display: grid;
  height: 100%;

  @include at-most-md {
    grid-template-rows: min-content min-content auto;
    grid-template-areas: "alertbar"
                         "sidebar"
                         "content";
  }
  @include at-least-md {
    grid-template-columns: 20rem auto;
    grid-template-rows: min-content;
    grid-template-areas: "alertbar alertbar"
                         "sidebar  content";
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  grid-area: content;
  overflow: auto;

  @include at-most-md {
    min-height: 100%;
  }
  @include at-least-md {
    border-left: .1rem solid $line;
  }
}
