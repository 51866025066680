.expired-code {
  grid-area: alertbar;
  height: min-content;
  background-color: #fff;
  padding: 1.6rem 8rem 1.6rem 2.4rem;
  border-bottom: .1rem solid $line;

  @include at-most-md {
    padding: 1.6rem 4rem 1.6rem 1.6rem;
  }
  @include at-least-md {
    display: flex;
    align-items: center;
  }
  &--text {
    font-size: 1.2rem;
    line-height: 1.8rem;

    @include at-least-md {
      margin-right: 5rem;
    }
  }
  &--buttons {
    display: flex;
    align-items: center;

    @include at-most-md {
      margin-top: 1.6rem;
    }
    @include at-least-md {
      margin-left: auto;
    }
    .btn {
      white-space: nowrap;

      & + .btn {
        margin-left: 1.6rem;
      }
    }
  }
  .close-btn {
    position: absolute;
    top: .8rem;
    right: .8rem;
  }
}
.trend-book--viewer {
  .expired-code {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
}
