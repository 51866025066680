/* MEDIA QUERIES */

@mixin at-most-sm {
  @media (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}
@mixin at-least-sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin at-most-md {
  @media (max-width: #{$screen-md - 1px}) {
    @content;
  }
}
@mixin at-least-md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin at-most-lg {
  @media (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}
@mixin at-least-lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin at-most-xl {
  @media (max-width: #{$screen-xl - 1px}) {
    @content;
  }
}
@mixin at-least-xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin at-most-xxl {
  @media (max-width: #{$screen-xxl - 1px}) {
    @content;
  }
}
@mixin at-least-xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

/* OBJECT-FIT (optional: add object-fit-images npm package for IE11/Edge Polyfill)
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}
