.modal {
  z-index: 100;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 6.2rem;
  overflow: auto;
  background-color: rgba(255, 255, 255, .85);
  backdrop-filter: blur(.5rem);

  &.show {
    display: flex;
  }
  @include at-most-md {
    padding: 2.4rem 1.6rem;
  }
}
.modal-animate-top {
  position: relative;
  animation: animatetop .4s;
}
.modal-dialog {
  width: 38rem;
  margin: auto;
  outline: 0;

  @include at-most-sm {
    width: 100%;
  }
  .close-btn {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }
  &.large {
    width: 45rem;

    .close-btn {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;

      @include at-most-sm {
        top: .8rem;
        right: .8rem;
      }
    }
  }
  .panel-addon {
    z-index: 1;
    position: relative;
    bottom: -.1rem;
  }
}
.modal-panel {
  position: relative;
  background-color: #fff;
  padding: 4rem;
  border: .1rem solid $line;

  @include at-most-sm {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
  }
  .legal-mentions {
    margin-top: 4rem;
  }
}
.modal-title {
  margin-bottom: 4rem;
  font-family: 'Brut Grotesque Regular', sans-serif;
  font-size: 1.8rem;
  line-height: 2.6rem;

  .modal-subtitle {
    margin-top: .8rem;
    font-family: 'Brut Grotesque Text', sans-serif;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
  .opacity,
  &.opacity {
    opacity: .7;
  }
}
.modal-text {
  font-size: 1.6rem;
  line-height: 2.4rem;

  &.text-center {
    text-align: center;
  }
  & + .modal-text {
    margin-top: 3.2rem;
  }
}
.modal-icon {
  margin-bottom: 1.6rem;
  text-align: center;

  .icon {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 2.4rem 2.4rem;
    margin: 0 auto .4rem;
    border: .1rem solid;
    border-radius: 50%;
  }
  .text {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}
.modal-success {
  .icon {
    border-color: $green;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M12 0L4.707 7.294 1.414 4.001 0 5.414l4.707 4.707 8.708-8.706z'/%3E%3C/defs%3E%3Cg transform='translate(-12 -12)' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke='%236DEA7B' cx='24' cy='24' r='24'/%3E%3Cuse fill='%236DEA7B' xlink:href='%23a' transform='translate(17 19)'/%3E%3C/g%3E%3C/svg%3E%0A"); // check.svg
  }
  .text {
    color: $green;
  }
}
.modal-error {
  .icon {
    border-color: $red;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h48v48H0z'/%3E%3Cpath d='M13.155 16.908v2.31h-2.31v-2.31h2.31zm-.077-12.127v4.813l-.27 5.39h-1.617l-.27-5.39V4.781h2.157z' id='b'/%3E%3C/defs%3E%3Cg transform='translate(-548 -237)' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke='%23E00F00' cx='560' cy='249' r='24'/%3E%3Cuse fill='%23E00F00' xlink:href='%23b' transform='translate(548 237)'/%3E%3C/g%3E%3C/svg%3E%0A"); // error.svg
  }
  .text {
    color: $red;
  }
}
@keyframes animatetop {
  from {
    top: -25vh;
    opacity: 0
  } to {
    top: 0;
    opacity: 1
  }
}
