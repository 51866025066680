.renew-code {
  padding: 1.6rem;

  &.show {
    display: flex;
  }
  .modal-dialog {
    max-width: 100%;
    padding: 0;
    width: 84.2rem;

    @include at-most-md {
      width: auto;
    }
  }
  .modal-panel {
    display: flex;
    padding: 0;

    @include at-most-md {
      flex-direction: column;
    }
  }
  &--image {
    @include at-least-md {
      flex-basis: 40%;
    }
    &--img {
      display: block;
      width: 100%;
      height: 100%;
      @include object-fit(cover, center);
    }
  }
  &--content {
    display: flex;
    flex-direction: column;

    @include at-least-md {
      flex-basis: 60%;
    }
    p {
      opacity: .7;
    }
  }
  &--form,
  &--footer {
    padding: 4rem;

    @include at-most-md {
      padding-right: 2.4rem;
      padding-left: 2.4rem;
    }
  }
  &--form {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  &--title {
    margin-bottom: 1.5rem;
    font-family: 'Peclers', serif;
    font-size: 5.6rem;
    line-height: 5.8rem;

    @include at-most-md {
      margin-bottom: 1.2rem;
      font-size: 4.8rem;
    }
  }
  &--help {
    margin-bottom: 3.2rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  &--footer {
    display: flex;
    align-items: center;
    border-top: .1rem solid $line;
    margin-top: auto;

    @include at-most-md {
      flex-direction: column;
      padding: 2.4rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.8rem;

      @include at-most-md {
        text-align: center;
      }
    }
    .arrow-btn {
      @include at-most-md {
        margin-top: 2.4rem;
      }
      @include at-least-md {
        margin-left: 2.4rem;
      }
    }
  }
}
