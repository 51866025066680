.category-nav {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: .8rem;
    bottom: .3rem;
    width: 17rem;

    @include at-most-md {
      width: 3.2rem;
    }
  }
  &::before {
    z-index: 1;
    left: 0;
    background-image: linear-gradient(90deg, #FFFFFF 37%, rgba(255,255,255,0.00) 100%);
  }
  &::after {
    right: 0;
    background-image: linear-gradient(270deg, #fff 37%, rgba(255, 255, 255, 0) 100%);
  }
  &--wrapper {
    position: relative;
    display: flex;
    height: 4.8rem;
    flex: 0 0 auto;
  
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: .1rem;
      background-color: $line;
      content: "";
    }
  }
  &--container {
    width: 100%;

    @include at-most-sm {
      &:not(:only-child) {
        max-width: 75%;
      }
    }
  }
  &--scroll {
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 0;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .nav-link {
    opacity: .7;
    position: relative;
    display: inline-block;
    padding-top: 2.1rem;
    padding-bottom: 1.3rem;
    border-bottom: .3rem solid transparent;
    margin-right: 2rem;
    font-family: 'Brut Grotesque Text';
    font-size: 1.1rem;
    line-height: 1.1rem;
    text-transform: uppercase;
  
    @include at-most-md {
      margin-right: 1.6rem;
    }
    &:first-child {
      margin-left: 4rem;

      @include at-most-lg {
        margin-left: 3.2rem;
      }
      @include at-most-md {
        margin-left: 1.6rem;
      }
    }
    &:last-child {
      @include at-least-md {
        margin-right: 2.2rem;
      }
    }
    &:hover,
    &.active {
      opacity: 1;
      border-bottom-color: $deepblue;
      font-family: 'Brut Grotesque Text';
    }
  }
  &--btn {
    display: none;

    @include at-least-md {
      z-index: 2;
      position: absolute;
      top: 50%;
      width: 2.4rem;
      height: 2.4rem;
      background: url(../../images/icons/chevron-right--black.svg) no-repeat;
      transform: translateY(-50%);

      &.right {
        right: 4rem;
      }
      &.left {
        left: 4rem;
        transform: translateY(-50%) scaleX(-1);
      }
    }
  }
  &.show-end {
    &::after {
      content: "";
    }
    .right {
      display: block;
    }
  }
  &.show-start {
    &::before {
      content: "";
    }
    .left {
      display: block;
    }
  }
}
