.trend-report {
  &--grid {
    @include at-most-md {
      padding-top: 3.2rem;
    }
    @include at-least-md {
      padding: 3.2rem;
    }
    @include at-least-lg {
      padding: 4rem;
    }
    &--title {
      margin-bottom: 2.4rem;

      @include at-most-md {
        padding-right: 1.6rem;
        padding-left: 1.6rem;
      }
    }
    &--items {
      @include at-least-md {
        position: relative;
        display: flex;
        margin-right: -1.6rem;
        margin-left: -1.6rem;
        flex-wrap: wrap;
      }
    }
    & + .trend-report--grid {
      @include at-most-md {
        padding-top: 0;

        .trend-report--large {
          padding-top: 1.6rem;
          border-top: .1rem solid $line;
        }
      }
    }
    &--link {
      margin-top: 8.8rem;
  
      @include at-most-md {
        padding-bottom: 3.2rem;
        margin-left: 1.6rem;
        margin-top: 4rem;
      }
      @include at-least-md {
        float: right;
      }
    }
  }
  &--thumbnail {
    @include at-most-md {
      padding: 1.6rem 1.6rem 5.6rem;
  
      & + .trend-report--thumbnail {
        border-top: .1rem solid $line;
      }
      &:last-of-type {
        border-bottom: .1rem solid $line;
      }
    }
    @include at-least-md {
      width: 33.3333%;
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }
    &--inner {
      position: relative;
      display: block;

      @media (pointer: fine) {
        &:hover {
          .trend-report--thumbnail--image {
            .lazy-image--source {
              transform: scale(1.05);
            }
          }
        }
      }
    }
    &--is-new {
      z-index: 1;
      position: absolute;
      top: .8rem;
      right: .8rem;
      background-color: #fff;
      padding: .8rem .8rem .4rem;
      margin-bottom: .8rem;
      font-family: 'Brut Grotesque Medium', sans-serif;
      font-size: .7rem;
      line-height: .8rem;
      text-transform: uppercase;
    }
    &--taxonomy {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.2rem;
      line-height: 1;
      text-transform: uppercase;
    }
    &--section,
    &--keyword {
      margin-right: .8rem;
    }
    &--keyword {
      opacity: .3;
    }
    &--image {
      position: relative;
      margin-top: .8rem;
      
      .lazy-image--source {
        transition: transform .5s ease-out;
      }
    }
    &--title {
      margin-top: 2.4rem;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
    &--date {
      opacity: .5;
      display: block;
      margin-top: 3.2rem;
      font-family: 'Brut Grotesque Bold';
      font-size: 1rem;
      line-height: 1rem;
      text-transform: uppercase;
  
      @include at-most-md {
        margin-top: 1.6rem;
      }
    }
  }
  &--large {
    width: 100%;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    margin-bottom: 4rem;

    @include at-most-md {
      margin-bottom: 3.2rem;

      &:not(:first-child) {
        padding-top: 1.6rem;
        border-top: .1rem solid $line;
      }
    }
    @include at-least-md {
      &:not(:first-child) {
        margin-top: 8rem;
      }
    }
    .trend-report--thumbnail {
      &--image {
        margin-top: 0;
      }
      &--is-new {
        top: 1.6rem;
        right: 1.6rem;
      }
      &--content {
        background-color: $deepblue;
        padding: 3.2rem;
        color: #fff;

        @include at-least-lg {
          position: absolute;
          top: 50%;
          left: 0;
          width: 41.6667%;
          min-height: 60%;
          padding-bottom: 7.6rem;
          transform: translateY(-50%);
        }
        @include at-least-xl {
          width: 33.3333%;
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
      &--title {
        margin-top: .8rem;
        font-family: 'Peclers', serif;
        font-size: 3rem;
        line-height: 3rem;

        @include at-most-md {
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }
      &--tag {
        opacity: .4;
      }
      &--date {
        line-height: 1.2rem;

        @include at-most-lg {
          margin-top: 2.4rem;
        }
        @include at-least-lg {
          position: absolute;
          bottom: 3.2rem;
          left: 3.2rem;
        }
      }
    }
  }
  &--article {
    padding-bottom: 4.8rem;

    @include at-most-md {
      padding-bottom: 4rem;
    }
    &-page {
      .trend-report--grid {
        padding-top: 4rem;
        border-top: .1rem solid $line;
      }
    }
    &--btn-back {
      margin: 3.2rem 0 .8rem 4rem;

      @include at-most-md {
        margin: 1.6rem 0 2.4rem 1.6rem;
        font-size: 0;
      }
    }
    &--header {
      padding: 0 12.5%;
      text-align: center;

      @include at-most-lg {
        padding: 0 3.2rem;
      }
      @include at-most-md {
        padding: 0 1.6rem;
      }
    }
    &--title {
      padding-top: 3.2rem;
      font-family: 'Peclers', serif;
      font-size: 4.6rem;
      line-height: 5.4rem;

      @include at-most-md {
        padding-top: 1.6rem;
        font-size: 3.4rem;
        line-height: 4rem;
      }
    }
    &--date-author {
      margin-top: 2.4rem;
      opacity: .5;
      font-family: 'Brut Grotesque Bold', sans-serif;
      font-size: 1rem;
      line-height: 1rem;
      text-transform: uppercase;
    }
    &--content {
      margin-top: 5.6rem;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        margin-top: 4rem;
        margin-bottom: 4rem;

        @include at-most-md {
          margin-top: 2.4rem;
          margin-bottom: 2.4rem;
        }
        & + blockquote {
          margin-top: 4rem;

          @include at-most-md {
            margin-top: 1.6rem;
          }
        }
      }
      [data-align="left"] {
        float: left;
        margin: 0 4rem .8rem 0;
      }
      [data-align="right"] {
        float: right;
        margin: 0 0 .8rem 4rem;
      }
      a {
        color: $red;
        text-decoration: underline;
  
        &:hover {
          color: darken($red, 10%);
        }
      }
      h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
        margin-top: 4rem;
        margin-bottom: 4rem;
        color: #2c2c2c;
        font-size: 1.8rem;
        line-height: 3.2rem;
        text-align: left;

        @include at-most-md {
          margin-top: 2.4rem;
          margin-bottom: 2.4rem;
          line-height: 3rem;
        }
      }
      h1, h2, h3, h4, h5, h6, p, ul, ol {
        padding-right: 12.5%;
        padding-left: 12.5%;

        @include at-most-lg {
          padding-right: 3.2rem;
          padding-left: 3.2rem;
        }
        @include at-most-md {
          padding-right: 1.6rem;
          padding-left: 1.6rem;
        }
      }
      li + li {
        margin-top: 1.6rem;
      }
      blockquote {
        padding-right: 25%;
        padding-left: 25%;
        font-family: 'Peclers', serif;
        font-size: 2.8rem;
        line-height: 3.6rem;
        text-align: center;
        margin: 4rem 0;

        @include at-most-lg {
          padding-right: 4rem;
          padding-left: 4rem;
        }
        @include at-most-md {
          padding-right: 1.6rem;
          padding-left: 1.6rem;
        }
        @include at-least-md {
          margin: 8rem 0;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: inherit;
          line-height: inherit;
          text-align: inherit;
        }
        & + img {
          margin-top: 0;
        }
      }
      ol {
        padding-left: 5.8rem;

        @include at-most-md {
          padding-left: 3.6rem;
        }
      }
      ul {
        padding-left: 4rem;

        @include at-most-md {
          padding-left: 1.6rem;
        }
        li {
          position: relative;
          padding-left: 2.4rem;
          list-style: none;

          &::before {
            position: absolute;
            left: 0;
            top: 0;
            color: $red;
            font-weight: bold;
            content: "\2022";
          }
        }
      }
      h2 {
        margin-top: 8rem;
        font-family: 'Brut Grotesque Regular', sans-serif;
        font-size: 3rem;
        line-height: 4rem;
      }
      h3 {
        font-family: 'Brut Grotesque Medium', sans-serif;
        line-height: 3rem;
      }
    }
  }
}
