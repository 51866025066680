.page-header {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
  border-bottom: .1rem solid $line;
  text-align: center;

  @include at-most-md {
    padding: 4.8rem 1.6rem;
  }
  &--title,
  &--text {
    @include at-least-md {
      max-width: 55%;
      margin: 0 auto;
    }
  }
  &--text {
    opacity: .7;
    margin-top: 1.6rem;
    font-family: 'Brut Grotesque Light', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include at-most-md {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  &--link {
    margin-top: 4rem;
  }
}
