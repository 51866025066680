.footer {
  padding: 1.6rem 3.2rem 4rem;
  border-top: .1rem solid $line;
  margin-top: auto;
  font-size: 0;
  text-align: center;
  text-transform: uppercase;

  @include at-most-md {
    padding: 1.2rem 1.6rem 3.2rem;
    font-size: 1rem;
    text-align: left;
  }
  .nav-item {
    display: inline-block;

    @include at-most-md {
      display: block;
    }
  }
  .nav-item {
    @include at-most-md {
      margin-top: 2rem;
    }
    @include at-least-md {
      margin: 2.4rem 1.6rem 0;
    }
  }
  .nav-link {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-decoration: none;
  }
  a.nav-link {
    &:hover,
    &.active {
      color: #000;
    }
  }
}
