*,
::after,
::before {
  box-sizing: border-box;
}
html.prevent-scroll,
html.prevent-scroll body {
  overflow: hidden;
}
html {
  font-size: 62.5%;
  word-break: normal;
  scroll-behavior: smooth;
}
body {
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #fff;
  color: $deepblue;
  font-family: 'Brut Grotesque Text', Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
p,
form {
  margin: 0;
}
a {
  color: $deepblue;
  text-decoration: none;
  transition: color 150ms linear;
}
ins {
  text-decoration: none;
}
b,
strong {
  font-family: 'Brut Grotesque Medium';
}
i,
em {
  font-style: italic;
}
u {
  text-decoration: underline;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.25em;
}
a,
button {
  &:focus {
    outline: 0 none;
    box-shadow: none;
  }
}
iframe {
  border: 0 none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* UTILITIES */
.fluid-img {
  width: 100%;
  height: auto;
}
.visually-hidden {
  display: none !important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
@include at-most-md {
  .visible-sm {
    display: none !important;
  }
}
@include at-least-md {
  .md-hide {
    display: none !important;
  }
}
@include at-most-lg {
  .visible-md {
    display: none !important;
  }
}
@include at-least-lg {
  .lg-hide {
    display: none !important;
  }
}
.text-center {
  text-align: center !important;
}
.list-unstyled,
.nav {
  padding: 0;
  margin: 0;
  list-style: none;
}
.screen-reader-text {
  display: none !important;
}
.has-stretched-link {
  position: relative;
}
.stretched-link {
  &-before {
    &::before {
      pointer-events: auto;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      content: "";
    }
  }
  &-after {
    &::after {
      pointer-events: auto;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      content: "";
    }
  }
}

.legal-mentions {
  font-size: .8rem;
  line-height: 1rem;
}

.is-new {
  background-color: #fff;
  padding: .8rem .8rem .4rem;
  margin-bottom: .8rem;
  font-family: 'Brut Grotesque Medium', sans-serif;
  font-size: .7rem;
  line-height: .8rem;
  text-transform: uppercase;
}
