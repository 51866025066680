.panel {
  background-color: #fff;
  padding: 4rem 2.4rem 2.4rem;

  @include at-least-md {
    padding: 4rem;
  }
  &-addon {
    background-color: #fff;
    padding: 2.4rem;
    border-top: .1rem solid $line;
    
    @include at-least-md {
      padding: 3.2rem 4rem;
    }
    &.small {
      padding: 2.4rem;
    }
    &.error {
      display: flex;
      align-items: center;
      padding: 2.4rem;
      border: .1rem solid $red;
      color: $red;
      font-size: 1.4rem;
      line-height: 2rem;

      &::before {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        width: 2.4rem;
        height: 2.4rem;
        background: url(../../images/icons/error-circle.svg) no-repeat;
        margin-right: 1.6rem;
        margin-top: -.4rem;
        content: "";
      }
    }
    button {
      line-height: 1.3;
    }
  }
}
.side-panel {
  width: 100%;
  overflow-y: auto;

  @include at-least-md {
    width: 38rem;
  }
}
