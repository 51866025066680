.runway-shows {
  &--season-nav {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 1.8rem 4rem 1rem;
    border-bottom: .1rem solid $line;

    @include at-most-lg {
      padding-right: 3.2rem;
      padding-left: 3.2rem;
    }
    @include at-most-md {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }
    &--label {
      opacity: .3;
      margin-right: .5rem;
      font-size: 3.2rem;
      line-height: 4.2rem;
    }
    .custom-select {
      position: relative;

      &--trigger {
        padding: 0 1.9rem;
        font-size: 3.2rem;
        line-height: 4.2rem;

        &::after {
          margin-top: .5rem;
          margin-left: .4rem;
        }
      }
      &--dropdown {
        padding: 1.7rem 1.9rem 4.4rem;

        &--item {
          opacity: .3;
          display: block;
          width: 100%;
          color: #000;
          font-size: 2rem;
          line-height: 3.8rem;
          text-align: left;
          transition: opacity .2s ease;

          &:hover,
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
  .category-nav--wrapper {
    @include at-most-sm {
      flex-direction: column;
      height: auto;
    }
  }
  .category-nav--container {
    @include at-most-sm {
      &:not(:only-child) {
        position: relative;
        max-width: none;

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: .1rem;
          background-color: #dfdfdf;
          content: "";
        }
      }
    }
  }
  &--city-selector {
    border-bottom: .1rem solid $line;
    border-left: .1rem solid $line;

    @include at-most-sm {
      border-left: 0 none;
    }
    .custom-select {
      &--trigger {
        opacity: .7;
        padding: 2.1rem 3.2rem 1.1rem 2.4rem;
        font-size: 1.1rem;
        line-height: 1.1rem;
        text-transform: uppercase;

        @include at-most-lg {
          padding-right: 2.4rem;
          padding-left: 1.6rem
        }
        @include at-most-md {
          padding-right: .4rem;
          padding-left: 1.2rem
        }
        @include at-most-sm {
          padding-left: 1.6rem;
        }
        &::after {
          margin-top: -.9rem;
          margin-left: 3.2rem;

          @include at-most-lg {
            margin-left: 1.6rem;
          }
          @include at-most-md {
            margin-left: .4rem;
          }
        }
      }
      &--dropdown {
        padding-top: .4rem;
        padding-bottom: .8rem;
        border: .1rem solid $line;
        border-right: 0 none;
        margin-right: -.1rem;
        margin-left: -.1rem;

        &--item {
          opacity: .7;
          padding: 1.6rem 3.2rem .8rem 2.4rem;
          transition: opacity .2s ease;
          font-size: 1.1rem;
          line-height: 1.1rem;
          text-transform: uppercase;

          @include at-most-md {
            padding-right: .4rem;
            padding-left: 1.2rem
          }
          &:hover,
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}
.runway-show {
  &--theme {
    &--block {
      @include at-most-md {
        padding-top: 3.2rem;
      }
      @include at-least-md {
        padding: 3.2rem;
      }
      @include at-least-lg {
        padding: 4rem;
      }
      &--title {
        @include at-most-md {
          padding-right: 1.6rem;
          padding-left: 1.6rem;
        }
      }
      &--link {
        margin-top: 2.4rem;
  
        @include at-most-md {
          margin-left: 1.6rem;
          margin-top: 8rem;
        }
        @include at-least-md {
          float: right;
        }
      }
    }
    &--item {
      @include at-least-md {
        margin-bottom: 6.4rem;
      }
      &--section {
        margin-top: 2.4rem;
        color: $grey;
        font-size: 1.2rem;
        line-height: 1;
        text-transform: uppercase;
      }
      &--theme {
        margin-top: .6rem;
        font-size: 1.6rem;
        line-height: 2rem;
      }
      @media (pointer: fine) {
        &:hover {
          .runway-show--theme--gallery {
            .lazy-image--source {
              transform: scale(1.05);
            }
          }
        }
      }
    }
    &--gallery {
      position: relative;
  
      &--city {
        z-index: 10;
        position: absolute;
        top: 1.6rem;
        left: 1.6rem;
        color: #fff;
        font-family: 'Brut Grotesque Regular', sans-serif;
        font-size: 1.1rem;
        line-height: 1.1rem;
      }
      .lazy-image {
        &--source {
          transition: transform .5s ease-out;
        }
        &::before,
        &::after {
          position: absolute;
          content: "";
        }
        &::before {
          z-index: 1;
          top: -6.8rem;
          left: -6.5rem;
          width: 38rem;
          height: 10.4rem;
          background-image: linear-gradient(180deg, rgba($deepblue, 0) 0%, rgba($deepblue, .50) 100%);
          background-repeat: no-repeat;
          transform: rotate(162deg);
        }
        &::after {
          z-index: 2;
          opacity: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba($deepblue, .1);
          transition: opacity .3s ease;
        }
      }
      .slick-arrow {
        @extend .btn-unstyled;
        position: absolute;
        top: 50%;
        cursor: pointer;
        z-index: 20;
        width: 3.2rem;
        height: 3.2rem;
        display: none !important;
        align-items: center;
        justify-content: center;
        background-color: rgba($deepblue, .2);
        border-radius: 50%;
        font-size: 0;
        text-align: center;
        transition: background-color .2s ease;
        transform: translateY(-50%);
  
        @include at-least-md {
          opacity: 0;
        }
        &:not(.slick-disabled) {
          display: flex !important;
        }
        &:hover {
          background-color: rgba($deepblue, .3);
        }
        &::before {
          display: block;
          flex: 0 0 auto;
          width: 2.4rem;
          height: 2.4rem;
          background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M9.712 6L9 6.702l5.653 5.729L9.158 18l.712.702 6.189-6.271z'/%3E%3C/defs%3E%3Cuse fill='%23FFFFFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat;
          background-size: contain;
          content: "";
        }
      }
      .slick-prev {
        left: .8rem;

        &::before {
          transform: scaleX(-1);
        }
      }
      .slick-next {
        right: .8rem;
      }
      .slick-dots {
        position: absolute;
        bottom: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        list-style: none;
        height: 4.1rem;
        background-image: linear-gradient(0deg, rgba($deepblue, .5) 0%, rgba($deepblue, 0) 100%);
        text-align: center;

        li {
          & + li {
            margin-left: .5rem;
          }
          button {
            @extend .btn-unstyled;
            display: block;
            width: .5rem;
            height: .5rem;
            background-color: #fff;
            border-radius: 50%;
            font-size: 0;
          }
          &.slick-active {
            button {
              background-color: $red;
            }
          }
        }
      }
      &:hover {
        .lazy-image {
          &::after {
            opacity: 1;
          }
        }
        .slick-arrow {
          opacity: 1;
        }
      }
    }
  }
  &--section-nav {
    position: relative;
    margin-top: 5.6rem;

    @include at-most-md {
      margin-top: 4rem;
    }
    &--scroll {
      overflow-y: hidden;
      overflow-x: auto;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      font-size: 0;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
      @include at-least-md {
        text-align: center;
      }
      &::before,
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 3.2rem;
        content: "";

        @include at-most-md {
          width: 1.6rem;
        }
        @include at-least-lg {
          width: 4rem;
        }
      }
      &::before {
        z-index: 1;
        left: 0;
        background-image: linear-gradient(90deg, #FFFFFF 37%, rgba(255, 255, 255, 0) 100%);
      }
      &::after {
        right: 0;
        background-image: linear-gradient(270deg, #fff 37%, rgba(255, 255, 255, 0) 100%);
      }
    }
    &--item {
      opacity: .5;
      display: inline-block;
      font-size: 1.2rem;
      line-height: 1.4rem;
      line-height: 1.6rem;
      margin-right: 1.2rem;
      margin-left: 1.2rem;

      &:first-child {
        margin-left: 4rem;

        @include at-most-md {
          margin-left: 1.6rem;
        }
      }
      &:last-child {
        margin-right: 4rem;

        @include at-most-md {
          margin-right: 2.4rem;
        }
      }
      &.active {
        opacity: 1;
      }
    }
  }
  &--thumbnail {
    &--image {
      position: relative;

      &::before {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(0deg, rgba($deepblue, .1) 0%, rgba($deepblue, .1) 100%);
        content: "";
        transition: background-image .3s ease;
      }
      &--right {
        z-index: 2;
        position: absolute;
        top: .8rem;
        right: .8rem;
        text-align: right;
      }
    }
    &--city {
      @extend .runway-show--theme--gallery--city;
    }
    &--is-new {
      background-color: #fff;
      padding: .8rem .8rem .4rem;
      margin-bottom: .8rem;
      font-family: 'Brut Grotesque Medium', sans-serif;
      font-size: .7rem;
      line-height: .8rem;
      text-transform: uppercase;
    }
    &--download {
      opacity: 0;
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      background: rgba(#fff, .15) url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M16 17v1H9v-1h7zM13 6v5.793l3.146-3.146.707.707-4.354 4.354-4.353-4.354.707-.707L12 11.793V6h1z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat; // (/assets/images/icons/download-small.svg)
      border-radius: 50%;
      transition: background-color .3s ease;

      &:hover {
        background-color: rgba(#fff, .25);
      }
    }
    &:hover {
      .runway-show--thumbnail {
        &--download {
          opacity: 1;
        }
        &--image {
          &::before {
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
            content: "";
          }
        }
      }
    }
    &--credit {
      margin-top: 2.4rem;
      font-size: 1.6rem;
      line-height: 1;
    }
    &--info {
      margin-top: .8rem;
      color: $grey;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
  &--block {
    padding-bottom: 0;

    .runway-show--thumbnail {
      margin-bottom: 5.6rem;

      @include at-most-md {
        margin-bottom: 3.2rem;
      }
    }
  }
}
