.nav {
  grid-area: sidebar;
  background-color: #fff;

  @include at-most-md {
    &.show {
      .nav-menu {
        left: 0;
      }
    }
  }
  @include at-least-md {
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding: 4rem 1.6rem 2.4rem 2.4rem;
  }
  .navbar {
    @include at-most-md {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.6rem;
      border-bottom: .1rem solid $line;
    }
  }
  &--logo {
    img {
      display: block;
      width: 13.5rem;
      height: auto;

      @include at-most-md {
        width: 10.4rem;
      }
    }
  }
  .btn-menu {
    display: none;

    @include at-most-md {
      position: relative;
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M21 18v2H3v-2h18zm0-7v2H3v-2h18zm0-7v2H3V4h18z' id='a'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat;
    }
    &.btn-close {
      display: block;
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.021 6l-5.657 5.656L6.706 6 6 6.707l5.656 5.657L6 18.02l.706.708 5.658-5.657 5.657 5.657.706-.708-5.656-5.656 5.656-5.657z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    }
  }
  .nav-link {
    opacity: .7;
    color: #000;
    font-size: 1.2rem;
    line-height: 1.3rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .2s ease;

    @include at-most-md {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
    &:hover,
    &.active {
      opacity: 1;
    }
    &.active {
      font-family: 'Brut Grotesque Medium';
    }
  }
  .nav-menu {
    display: flex;
    flex-direction: column;

    @include at-most-md {
      z-index: 1;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: -100vw;
      bottom: 0;
      width: 100vw;
      min-height: 100%;
      background-color: #fff;
      transition: left .3s ease-in-out;
    }
    @include at-least-md {
      flex: 1;
    }
  }
  &--main-menu {
    margin-top: 8rem;

    @include at-most-md {
      margin-top: 5.6rem;
      border-top: .1rem solid $line;
      padding: 3.2rem;
    }
    .nav-item {
      & + .nav-item {
        margin-top: 2.4rem;

        @include at-most-md {
          margin-top: 3.2rem;
        }
      }
    }
  }
  &--side-menu {
    margin-top: auto;

    @include at-most-md {
      padding: 3.2rem;
    }
    .nav-item {
      line-height: 0;

      & + .nav-item {
        margin-top: 1.2rem;

        @include at-most-md {
          margin-top: 2.4rem;
        }
      }
      &.lang-switcher {
        margin-top: 2rem;

        @include at-most-md {
          margin-top: 0;
        }
      }
    }
    .nav-link {
      display: inline-flex;
      align-items: flex-end;
      min-height: 2.4rem;

      &::before {
        width: 2.4rem;
        height: 2.4rem;
        background-repeat: no-repeat;
        content: "";
      }
      span {
        padding-bottom: .3rem;

        @include at-most-md {
          padding-bottom: .1rem;
        }
      }
    }
    .nav-profile {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23030304' stroke-linecap='square' stroke-linejoin='bevel' stroke-width='.965' fill='none' fill-rule='evenodd'%3E%3Cpath d='M.483 19.937c0-3.088 2.687-7.454 6-7.454s6 4.366 6 7.454l-1.068.166a32.182 32.182 0 01-9.865 0l-1.067-.166zM9.483 7.483a3 3 0 11-6.001-.001 3 3 0 016 0z'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
    .nav-help {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M.5 6l-.5.5v8.001L.5 15h3.793l3.853 3.854L9 18.5V15h3.5l.499-.499V6.5L12.5 6H.5zm.499 1h11v7h-3.5L8 14.501v2.792l-3.147-3.146L4.5 14H.999V7z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23002626' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
      }
    }
    .nav-logout {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M8.792 8l4.122 4.122v.707l-4 4-.707-.707 3.146-3.147H4.5v-1h6.853L8.086 8.706 8.792 8zm-7.791.353v8.122H0V8.353h1.001z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23002626' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
      }
    }
  }
  .lang-switcher {
    @include at-most-md {
      position: absolute;
      top: 2.3rem;
    }
    &--buttons {
      display: flex;
    }
    &--button {
      font-size: 1.2rem;
      line-height: 1.4rem;

      @include at-most-md {
        font-size: 1.6rem;
        line-height: 1.8rem;
      }
      &:hover {
        font-family: 'Brut Grotesque Medium';
      }
      & + .lang-switcher--button {
        margin-left: .7rem;

        @include at-most-md {
          margin-left: .8rem;
        }
      }
      &.active {
        color: $red;
        font-family: 'Brut Grotesque Bold';
      }
    }
  }
}
