@font-face {
  font-display: swap;
  font-family: 'Brut Grotesque Light';
  src: url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Light.woff2') format('woff2'),
       url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Light.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Brut Grotesque Text';
  src: url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Text.woff2') format('woff2'),
       url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Text.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Brut Grotesque Regular';
  src: url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Regular.woff2') format('woff2'),
       url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Brut Grotesque Medium';
  src: url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Medium.woff2') format('woff2'),
       url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Medium.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Brut Grotesque Bold';
  src: url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Bold.woff2') format('woff2'),
       url('../../fonts/brut_grotesque/Brut_Grotesque_WEB-Bold.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Peclers';
  src: url('../../fonts/peclers/Peclers_WEB-Bold.woff') format('woff'),
       url('../../fonts/peclers/Peclers_WEB-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

.lvl1-title {
  font-family: 'Peclers', serif;
  font-size: 6.4rem;
  line-height: 5.8rem;

  @include at-most-md {
    font-size: 4.4rem;
    line-height: 4.2rem;
  }
}

.lvl2-title {
  font-family: 'Peclers', serif;
  font-size: 3.6rem;
  line-height: 1;

  @include at-most-md {
    font-size: 3rem;
  }
}

.lvl3-title {
  font-size: 2.4rem;
  line-height: 1;
}
