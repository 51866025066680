.profile {
  .page-header--title {
    font-size: 4rem;
    line-height: 5rem;

    @include at-most-md {
      font-size: 3.4rem;
      line-height: 4.2rem;
    }
  }
  .page-header {
    &--title {
      font-size: 4rem;
      line-height: 5rem;
  
      @include at-most-md {
        font-size: 3.4rem;
        line-height: 4.2rem;
      }
    }
    &--text {
      margin-top: .4rem;
    }
    .btn-container {
      margin-top: 2.4rem;
    }
  }
  &--content {
    display: flex;
  
    @include at-most-md {
      flex-direction: column;
    }
    &--section {
      padding: 3.2rem;
      
      @include at-most-md {
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        border-bottom: .1rem solid $line;

        &:last-child {
          margin-bottom: 11.2rem;
        }
      }
      @include at-least-md {
        width: 50%;
        padding-bottom: 6.4rem;
      }
      @include at-least-lg {
        padding-right: 4rem;
        padding-left: 4rem;
      }
    }
    .Collapsible {
      &__trigger {
        font-size: 2.2rem;
        line-height: 3.6rem;

        @include at-most-md {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;

          &::after {
            flex: 0 0 auto;
            width: 2.4rem;
            height: 2.4rem;
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M9.712 6L9 6.702l5.653 5.729L9.158 18l.712.702 6.189-6.271z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' transform='rotate(90 12.53 12.351)' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat;
            margin-left: auto;
            content: "";
          }
        }
        @include at-least-md {
          cursor: default;
          pointer-events: none;
        }
      }
      &__contentOuter {
        @include at-least-md {
          overflow: initial !important;
          height: auto !important;
          transition: none !important;
        }
      }
      &__contentInner {
        padding-top: 2rem;
      }
    }
  }
}
.subscribers-university {
  padding: 4rem;
  
  @include at-most-md {
    padding: 1.6rem;
  }
}
.activation-code-box {
  display: flex;
  padding: 3.2rem;
  border: .1rem solid $line;
  margin-bottom: 4rem;

  @include at-most-lg {
    flex-direction: column;
    padding: 2.4rem;
    margin-bottom: 1.6rem;
  }
  @include at-least-lg {
    align-items: center;
    justify-content: space-between;
  }
  &--title {
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
  &--info {
    opacity: .7;
    font-family: 'Brut Grotesque Light', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  &--display {
    display: flex;
    align-items: center;
    
    @include at-most-lg {
      margin-top: 2.4rem;
    }
  }
  &--code {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 15.5rem;
    padding: 2.4rem 2rem 1.7rem;
    border-radius: .2rem;
    border: .1rem solid $blue;
    font-size: 1.6rem;
    letter-spacing: .05rem;
    line-height: 1;
    text-align: center;

    &.deprecated {
      border-color: $orange;
    }
    mark {
      background-color: rgba(250, 143, 49, .15);
    }
  }
  &--btn-copy {
    margin-top: .6rem;
    margin-left: 3.2rem;
  }
}
.subscribers-standard {
  padding-bottom: 8.2rem;

  @include at-most-md {
    padding-bottom: 4rem;
  }
  &--add-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 3.2rem .4rem;
    border-bottom: .1rem solid $line;

    @include at-most-md {
      padding: 1.7rem 1.7rem .5rem 1.6rem;
    }
    @include at-least-lg {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
  &--btn-add {
    color: $grey;
    font-size: 1.6rem;
    text-transform: uppercase;
    transition: color 150ms;
    text-align: left;

    &:hover {
      color: darken($grey, 10%);
    }
  }
  &--user-count {
    padding-left: 3.2rem;
    opacity: .6;
    color: $grey;
    font-size: 3.8rem;
    line-height: 6.4rem;
  }
  &--members-list {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;

    th,
    td {
      border-bottom: .1rem solid $line;
    }
    thead {
      th {
        background-color: rgba(216, 216, 216, .2);
        padding: 1.3rem 3.8rem .9rem;
        color: $grey;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.7rem;
        text-transform: uppercase;

        @include at-most-md {
          padding-right: 1.6rem;
          padding-left: 1.6rem;
        }
      }
    }
    tbody {
      td {
        width: 100%;
        padding: 2.5rem 4rem 2.1rem 3.7rem;

        @include at-most-md {
          padding: 2.3rem 1.6rem 1.7rem;
        }
      }
      .td-btn {
        width: 0;
        padding-left: 2.4rem;

        &:nth-last-child(2) {
          padding-right: 0;
        }
        @include at-most-md {
          width: 3.2rem;
          padding: 2rem 1.6rem 2rem .8rem;
        }
      }
    }
    &--name {
      font-size: 1.6rem;
      line-height: 1.8rem;

      @include at-most-md {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
    &--date {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
    &--btn-delete {
      @include at-most-md {
        overflow: hidden;
        width: 2.4rem;
        height: 2.4rem;
        background-image: url(../../images/icons/delete.svg);
        text-indent: -9999px;
      }
      @include at-least-md {
        opacity: .5;
        font-size: 1.2rem;
        line-height: 100%;
        text-transform: uppercase;
      }
    }
  }
  &--load-more {
    margin-top: 3.2rem;
  }
}
