.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  .side-panel {
    flex: 1;

    .legal-mentions {
      margin-top: 4rem;
    }
  }
}
