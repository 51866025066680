.login-panel {
	position: absolute;
	max-height: 100%;
	overflow-y: auto;

	@include at-most-sm {
		max-height: calc(100% - 3.2rem);
		left: 1.6rem;
		bottom: 4.8rem;
		right: 1.6rem;
	}
	@include at-least-sm {
		width: 38rem;
		top: 50%;
		left: 7.4rem;
		transform: translateY(-50%);
	}
}

.login-form-etudiant {
	align-items: center;

	.text {
		width: 50%;
		font-size: 1.4rem;
		line-height: 2rem;
	}
}

.forgot-pwd-link {
	padding: 1.4rem 0 .9rem;
	color: $grey;
	font-size: .8rem;
	text-decoration: none;
	text-transform: uppercase;
	transition: color .3s;

	&:hover {
		color: darken($grey, 30%);
	}
}

.panel-title {
	margin-bottom: .8rem;
	font-size: 1.8rem;
	line-height: 2.6rem;
}
.panel-text {
	margin-bottom: 4rem;
	opacity: .7;
	font-size: 1.4rem;
	line-height: 2.2rem;
}

.icp-link {
	position: absolute;
	bottom: 1.6rem;
	left: 50%;
	transform: translateX(-50%);
	color: rgba($line, .8);
	font-size: 1.2rem;
}
