/* reset */
@import '~normalize.css/normalize.css';

/* grid */
//@import '~reflex-grid/scss/reflex';

/* config */
@import './config/vars';
@import './config/mixins';
@import './config/typography';
@import './config/global';

/* elements */
@import './elements/buttons';
@import './elements/forms';
@import './elements/modals';
@import './elements/page';
@import './elements/panels';

/* components */
@import './components/category-block'; // block of items (trend books or runway shows)
@import './components/category-nav'; // category nav
@import './components/custom-select'; // custom select basis
@import './components/expired-code'; // expired code banner
@import './components/footer'; // site footer
@import './components/header'; // page header
@import './components/home-page'; // home page
@import './components/lazy-image'; // lazy loading image
@import './components/login'; // login page
@import './components/nav'; // site navigation
@import './components/notfound'; // 404 page
@import './components/profile'; // user profile
@import './components/static'; // static pages
@import './components/register'; // registration form
@import './components/renew-code'; // code renewal pop-ins
@import './components/runway-shows'; // runway shows
@import './components/trend-books'; // trend books
@import './components/trend-reports'; // trend reports
