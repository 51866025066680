/** colors **/

$deepblue: #1E1B31;
$blue: #6D8AEA;
$green: #6DEA7B;
$red: #E00F00;
$grey: #858585;
$line: #dfdfdf;

$forestgreen: #0A4749;
$orange: #E75800;
$black: #002626;
$beige: #EFE7D9;

/** breakpoints **/

// small devices
$screen-sm : 576px;
// medium devices
$screen-md : 768px;
// large devices
$screen-lg : 992px;
// xlarge devices
$screen-xl : 1200px;
// xxlarge devices
$screen-xxl : 1600px;
