.home-page {
  &--slider {
    position: relative;

    .slick-dots {
      position: absolute;
      top: calc(12.5% + 4rem);
      left: 7.2rem;
      padding: 0;
      margin: 0;
      font-size: 0;

      @include at-most-lg {
        top: 42.3rem;
        left: 4rem;
      }
      li {
        display: inline-block;

        & + li {
          margin-left: 1.7rem;
        }
        button {
          border-bottom: .1rem solid transparent;
          @extend .btn-unstyled;
          color: #fff;
          font-size: 1rem;
          line-height: 1.7rem;
        }
        &.slick-active {
          button {
            border-bottom-color: #fff;
          }
        }
      }
    }
    @include at-most-md {
      &::after {
        position: absolute;
        right: 1.6rem;
        bottom: 1.6rem;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M8.102 16L16 7.983 8.102 0 7.15.963l6.366 6.435H0v1.239h13.48l-6.331 6.4z'/%3E%3C/defs%3E%3Cuse fill='%23FFFFFF' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/svg%3E%0A");
        background-size: contain;
        transform: rotate(90deg);
        content: "";
      }
    }
  }
  &--slide {
    @include at-least-lg {
      position: relative;
    }
    .lazy-image {
      @include at-most-lg {
        height: 39.1rem;
      }
      img {
        @include at-most-lg {
          width: 100%;
          height: 100%;
          @include object-fit(cover, center);
        }
        @include at-least-lg {
          width: 100%;
          height: auto;
        }
      }
    }
    &--content {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      background-color: $deepblue;
      padding: 3.2rem 1.6rem 3.2rem 3.2rem;
      color: #fff;

      @include at-most-lg {
        position: relative;
        padding-top: 7.2rem;
      }
      @include at-least-lg {
        position: absolute;
        top: 50%;
        left: 4rem;
        width: 41%;
        min-height: 75%;
        transform: translateY(-50%);
      }
      @include at-least-xl {
        width: 36%;
      }
    }
    &--title {
      margin-top: .8rem;
      font-family: 'Peclers', serif;
      font-size: 4.3rem;
      line-height: 4.8rem;
  
      @include at-most-md {
        font-size: 3.4rem;
        line-height: 3.8rem;
      }
    }
    &--desc {
      opacity: .5;
      margin-top: 1.6rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  &--block {
    @include at-least-md {
      padding-bottom: 4rem;
    }
    &:not(:last-of-type) {
      border-bottom: .1rem solid $line;
    }
    &--title {
      padding: 3.2rem 4rem .8rem;

      @include at-most-lg {
        padding-left: 3.2rem;
      }
      @include at-most-md {
        padding-left: 1.6rem;
      }
    }
    &--link {
      @include at-most-md {
        width: 100%;
        padding: 3.2rem 1.6rem;
        margin: 0;
      }
    }
    .category-block {
      @include at-most-md {
        padding-bottom: 0;
      }
      &--slider {
        margin-top: 0;
      }
      &--link {
        @include at-most-md {
          margin-top: 4.8rem;
        }
      }
    }
  }
  &--trend-books {
    .trend-book--thumbnail--title {
      margin-bottom: .8rem;
      color: $deepblue;
      font-family: 'Peclers', serif;
      font-size: 1.8rem;
      line-height: 2rem;
    }
    .trend-book--thumbnail--season {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  &--runway-shows {
    .home-page--block--link {
      @include at-least-md {
        margin-top: 2.4rem;
      }
    }
  }
  &--trend-reports {
    .home-page--block--link {
      @include at-most-md {
        margin-top: 0;
      }
      @include at-least-md {
        margin-right: 4rem;
        margin-top: 4.8rem;
      }
    }
  }
}
