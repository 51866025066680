.trend-books {
  .trend-book--list {
    padding: 4rem;

    @include at-most-lg {
      padding: 3.2rem; 
    }
    @include at-most-md {
      padding: 3.2rem 1.6rem;
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3.2rem;
      margin-top: 2rem;
      
      @include at-most-lg {
        gap: 1.6rem;
      }
      @include at-most-md {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.6rem;
        margin-top: 1.6rem;
      }
      .trend-book--thumbnail {
        &--text {
          @include at-most-md {
            bottom: .8rem;
            left: .8rem;
            width: 54%;
            margin-right: .8rem;
            font-size: 1.4rem;
            line-height: 1.6rem;
          }
          @include at-most-sm {
            right: .8rem;
            width: auto;
            margin-right: 0;
          }
        }
      }
    }
  }
  .runway-show--list {
    border-top: .1rem solid $line;
  }
}
.trend-book {
  &--thumbnail {
    position: relative;
    display: block;
    overflow: hidden;

    &--text {
      position: absolute;
      bottom: 1.6rem;
      left: 1.6rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 66%;
      min-height: 9.6rem;
      padding: 2.4rem 1.6rem 1.2rem;

      @include at-most-md {
        min-height: 5.6rem;
      }
      @media (min-width: $screen-md) and (max-width: #{$screen-lg - 1px}) {
        left: .8rem;
        bottom: .8rem;
        right: .8rem;
        width: auto;
        padding: 1.2rem .8rem .4rem;
      }
      @include at-least-xl {
        width: 55%;
      }
    }
    &--title,
    &--season {
      color: $grey;
      font-size: 1.6rem;
      line-height: 1.8rem;

      @include at-most-md {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
    &--image {
      transition: transform .5s ease-out;
    }
    &--is-new {
      position: absolute;
      top: .8rem;
      right: .8rem;
      background-color: #fff;
      padding: .8rem .8rem .4rem;
      margin-bottom: .8rem;
      font-family: 'Brut Grotesque Medium', sans-serif;
      font-size: .7rem;
      line-height: .8rem;
      text-transform: uppercase;
    }
    @media (pointer: fine) {
      &:hover {
        .trend-book--thumbnail--image {
          transform: scale(1.05);
        }
      }
    }
  }
  &--front {
    &--banner {
      position: relative;
      color: #fff;
      
      @include at-most-md {
        padding: 1.6rem 1.6rem 2.4rem 1.6rem;
      }
      &--back {
        @include at-most-md {
          font-size: 0;
        }
        @include at-least-md {
          z-index: 1;
          position: absolute;
          top: 4rem;
          left: 4rem;
        }
      }
      &--image {
        @include at-most-md {
          z-index: -1;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);

          &::before,
          &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
          }
          &::before {
            top: 0;
            background-color: rgba(0, 0, 0, .5);
            backdrop-filter: blur(1rem);
          }
          &::after {
            height: 37%;
            background-image: linear-gradient(180deg, rgba(30, 27, 49, 0) 0%, rgba(30, 27, 49, .6) 100%);
          }
        }
        @include at-least-md {
          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .15);
            content: "";
          }
        }
        img {
          @include at-most-md {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          @include at-least-md {
            display: block;
            width: 100%;
          }
        }
      }
      &--thumbnail {
        @include at-most-md {
          display: block;
          width: 45%;
          margin: .8rem auto 0;
        }
        @include at-least-md {
          display: none;
        }
      }
      &--content {
        @include at-most-md {
          margin-top: 4rem;
        }
        @include at-least-md {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          background-image: linear-gradient(180deg, rgba(30, 27, 49, 0) 0%, rgba(30, 27, 49, .60) 100%);
          padding: 2.4rem 4rem 4rem;
        }
      }
      &--title {
        display: flex;
        align-items: center;
        font-family: 'Peclers', serif;
        font-size: 4rem;
        line-height: 1;

        @include at-most-lg {
          align-items: flex-start;
          flex-direction: column;
        }
      }
      &--not-included {
        display: inline-flex;
        background: rgba(0, 0, 0, .5);
        padding: .8rem .8rem .4rem;
        border: .1rem solid #fff;
        font-family: 'Brut Grotesque Text', Arial, Helvetica, sans-serif;
        font-size: .8rem;
        line-height: 1;
        text-transform: uppercase;

        &::before {
          display: block;
          width: .4rem;
          height: .4rem;
          background-color: $red;
          border-radius: 50%;
          margin-right: .4rem;
          content: "";
        }
        @include at-most-lg {
          margin-top: 1.2rem;
        }
        @include at-least-lg {
          margin-top: .4rem;
          margin-left: 1.6rem;
        }
      }
      &--season {
        margin-top: 1.6rem;
        font-size: 1.6rem;
        line-height: 1;
      }
      &--description {
        margin-top: 1.6rem;
        font-size: 1.4rem;
        line-height: 2rem;

        @include at-least-lg {
          max-width: 66%;
        }
        @include at-least-xl {
          max-width: 56%;
        }
      }
      &--button {
        flex-shrink: 0;

        @include at-most-md {
          margin-top: 2.4rem;
        }
        @include at-least-md {
          margin-left: 2.4rem;
        }
      }
    }
  }
}
.orientation-change {
  display: block;
  z-index: 900;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  padding: 1.6rem;
  text-align: center;

  &--icon-container {
    width: 20rem;
    height: 20rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -10rem;
    margin-top: -10rem;

    p {
      opacity: 0;
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: 0;
      color: #fff;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
  &--phone {
    position: relative;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border: 0 solid $line;
    background: $deepblue;
    border-radius: .8rem;
  }
  &--icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='%23fff' d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z'/%3E%3C/svg%3E") no-repeat;
    background-size: cover;
  }
  .orientation-change--phone {
    animation: sizeIncrease .5s forwards ease, borderIncrease .5s .5s forwards ease, rotateRight .7s 1s forwards ease;

    i {
      animation: fadeIn .5s .8s forwards ease;
    }
  }
  .orientation-change--icon-container {
    p {
      animation: fadeIn .5s 1.3s forwards ease;
    }
  }

  /* KEYFRAMES
  ------------------------------------------- */
  /* Animate width + height */
  @keyframes sizeIncrease {
    0%   { width: 0; height: 1rem; }
    50%  { width: 10rem; height: 1rem; }
    100% { width: 10rem; height: 16rem; }
  }

  /* Add borders */
  @keyframes borderIncrease { 
    100% { border-width: .6rem; } 
  }

  /* fade-in  */
  @keyframes fadeIn { 
    100% { color: #fff; opacity: 1; } 
  }

  /* Rotate device */
  @keyframes rotateRight { 
    100% { transform: rotate(90deg); } 
  }
}
.pdf-viewer {
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  background-color: #000;

  &--page {
    width: 80vw;
    height: 100%;

    @media (max-width: $screen-md - 1px) and (orientation: portrait) {
      width: 80vh;
    }
    & > div:first-child > div,
    & > div:first-child > div > div {
      overflow: hidden !important; // prevent natural scrolling inside the pdf
    }
  }
  .rpv-core__spinner-circle {
    stroke: rgba(255, 255, 255, .4);
  }
  .rpv-core__viewer {
    & > div {
      & > div {
        scrollbar-width: .4rem;
        scrollbar-color: rgba(#fff, .6) transparent;

        &::-webkit-scrollbar {
          width: .4rem;
          height: .4rem;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(#fff, .6);
        }
      }
    }
  }
  .rpv-core__inner-page {
    background-color: transparent;
    padding: .8rem;

    a:hover {
      background: none;
    }
  }
  &--right-nav,
  &--left-nav {
    position: relative;
    width: 10vw;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    
    @include at-most-md {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }
    @media (max-width: $screen-md - 1px) and (orientation: portrait) {
      width: 10vh;
    }
  }
  &--left-nav {
    &:not(.hovered) {
      .pdf-viewer--btn-back {
        &.hide {
          opacity: 0;
        }
      }
    }
  }
  &--thumbnail-hover-zone {
    position: absolute;
    bottom: -2.4rem;
    right: 35vw;
    left: 35vw;
    height: 4.8rem;
    border-radius: 50%;
  }
  &--thumbnails {
    z-index: 10;
    overflow: hidden;
    visibility: hidden;
    position: fixed;
    right: 10vw;
    bottom: -16.4rem;
    left: 10vw;
    height: 16.4rem;
    padding-bottom: 2.4rem;
    transition: left .2s ease, visibility .2s;

    @include at-most-md {
      height: 15.8rem;
      right: 1.6rem;
      bottom: -15.8rem;
      left: 1.6rem;
      padding-bottom: 1.6rem;
    }
  }
  .thumbnails-shown {
    .pdf-viewer--thumbnails {
      bottom: 0;
      visibility: visible;
    }
  }
  .rpv-thumbnail-list {
    overflow-x: auto;
    height: 14.3rem;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 2.4rem;

    &::-webkit-scrollbar {
      height: .5rem;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, .4);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red;
    }
  }
  .rpv-thumbnail-item {
    height: 9.5rem;
    padding: 0 .8rem;
    transition: height .3s;
    background-color: transparent;

    div,
    img {
      width: auto !important;
      height: 100% !important;
    }
    &:hover {
      height: 11.4rem;
    }
    .rpv-core__spinner {
      width: 6.2rem;
      height: 6.2rem;
    }
  }
  &--page-navigation,
  &--zoom {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 7.2rem;
    transform: translate(-50%, -50%);
  }
  &--zoom {
    .pdf-viewer--btn-zoom-out {
      margin-top: 2.4rem;
    }
  }
  &--btn {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-repeat: no-repeat;
    margin-right: auto;
    margin-left: auto;
    transition: opacity .2s;

    &[disabled] {
      opacity: 0;
    }
    &-back {
      opacity: 1;
      z-index: 1;
      position: absolute;
      top: 3.2rem;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .3s ease;

      @include at-most-lg {
        top: 2.4rem;
      }
      @include at-most-xl {
        font-size: 0;
        margin: 0;
      }
    }
    &-additional {
      position: absolute;
      bottom: 2.1rem;
      left: 50%;
      width: auto;
      height: auto;
      transform: translateX(-50%);
      font-size: 0;

      @include at-least-md {
        color: #fff;
        font-size: 1rem;
        line-height: 1.7rem;
        text-transform: uppercase;
      }
      &--icon {
        display: block;
        flex: 0 0 3.2rem;
        width: 3.2rem;
        height: 3.2rem;
        background: #fff url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M12 4v8H4v1h8v8h1v-8h8v-1h-8V4z'/%3E%3C/defs%3E%3Cuse fill='%23E00F00' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat center center; // plus--large.svg
        background-size: 1.6rem 1.6rem;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;

        @include at-least-md {
          margin-bottom: .8rem;
        }
        &.animate {
	        animation: flash 1s linear 0s 2;
        }
        @keyframes flash {
          0% { opacity: 1; } 
          50% { opacity: .1; } 
          100% { opacity: 1; }
        }
      }
      &--count {
        opacity: .5;
      }
    }
    &-prev,
    &-next {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M9.712 6L9 6.702l5.653 5.729L9.158 18l.712.702 6.189-6.271z'/%3E%3C/defs%3E%3Cuse fill='%23fff' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // (/assets/images/icons/chevron-right--white.svg)
    }
    &-prev {
      margin-top: 2.4rem;
      margin-bottom: auto;
      transform: scaleX(-1);

      @include at-most-md {
        margin-top: 1.6rem;
      }
    }
    &-next {
      margin-top: auto;
    }
    &-downloads {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M19 20v1H6v-1h13zM13 3v10.793l5.146-5.146.708.707-6.355 6.353-6.353-6.353.707-.707L12 13.793V3h1z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E"); // (/assets/images/icons/download.svg)
    }
    &-themes {
      margin-top: auto;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M17 5v4.001h5V19H8v-3.999H3V5h14zm4 5H9v8h12v-8zm-5-4H4v9h4V9h8V6z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // (/assets/images/icons/chapters.svg)
    }
    &-thumbnails {
      @include at-most-md {
        position: absolute;
        bottom: 1.6rem;
        left: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M9.712 6L9 6.702l5.653 5.729L9.158 18l.712.702 6.189-6.271z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' transform='rotate(90 12.53 12.351)' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
        transform: scaleY(-1) translateX(-50%);
      }
      @include at-least-md {
        display: none;
      }
    }
  }
  &--theme-list {
    z-index: 20;
    position: absolute;
    bottom: -100%;
    left: -19.2rem;
    width: 19.2rem;
    max-height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .9);
    transform: translateZ(0); // fixes a z-index bug on Safari
    transition: bottom .5s ease;

    &.show {
      bottom: 0;
    }
    &--inner {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.8rem;
    }
    &--title,
    &--btn {
      color: #fff;
      font-family: 'Brut Grotesque Regular', sans-serif;
      line-height: 1.2rem;
    }
    &--title {
      padding: 2.4rem 2.4rem 2.7rem 2.4rem;
      font-size: 1.8rem;
    }
    &--btn {
      opacity: .7;
      padding: 1.8rem 2.4rem 1.4rem;
      font-size: 1.2rem;
      text-align: left;

      &:hover,
      &.active {
        opacity: 1;
        background-color: rgba(216, 216, 216, .15);
      }
    }
  }
  &--additional-content {
    z-index: 20;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 100%;
    transition: left .5s ease;
    color: #fff;

    &.show {
      left: 0;
    }
    &--close-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
    }
    &--panel {
      overflow-y: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 23.1rem;
      background-color: rgba(0, 0, 0, .9);
    }
    &--list {
      padding: 4.8rem 2.4rem 2.4rem;

      &--title {
        margin-bottom: 1.6rem;
        font-family: 'Brut Grotesque Regular', sans-serif;
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }
    &--item {
      display: block;
      width: 100%;
      margin-bottom: 2.4rem;
      color: #fff;
      text-align: left;

      &--title {
        margin-top: .8rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
    &--image {
      position: relative;
    }
    &--graphic {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#fff, .7);

      .lazy-image {
        flex: 0 1 auto;
        width: 66.6667%;
      }
    }
    &--overlay {
      position: fixed;
      top: 100%;
      right: 0;
      height: 100%;
      left: 23.1rem;
      background-color: #000;
      transition: top .5s ease;

      &.show {
        top: 0;
      }
      &--inner {
        overflow-y: auto;
        height: 100%;
        padding: 4rem 12vw;
        border-left: .1rem solid $grey;

        &.video {
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }
    &--video {
      flex: 1;
      position: relative;
      padding-top: 56.25%;
    }
    &--video-player {
      position: absolute;
      top: 0;
      left: 0;
    }
    &--article {
      &--title {
        font-family: 'Peclers', serif;
        font-size: 4rem;
        line-height: 4.8rem;
        text-align: center;

        @include at-most-md {
          font-size: 3.4rem;
          line-height: 4rem;
        }
      }
      .pdf-viewer--additional-content--image,
      &--desc {
        margin-top: 4rem;
      }
      &--desc {
        font-size: 2rem;
        line-height: 3.4rem;

        @include at-most-md {
          font-size: 1.8rem;
          line-height: 3rem;
        }
      }
    }
    &--archestyle {
      .pdf-viewer--additional-content--article--image {
        position: relative;
      }
    }
  }
  &--downloads {
    z-index: 20;
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 100%;
    transition: right .5s ease;

    &.show {
      right: 0;
    }
    &--close-overlay {
      @include at-least-md {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);
        content: "";
      }
    }
    &--panel {
      overflow-y: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 66vw;
      background-color: #000;
      border-left: .1rem solid rgba($line, .4);
      padding-top: 4rem;
      color: #fff;

      @include at-most-md {
        width: 100vw;
        padding-top: 1.6rem;
      }
      @include at-least-md {
        width: 56vw;
      }
      @include at-least-xl {
        width: 46vw;
      }
    }
    &--title {
      padding-left: 3.2rem;
      font-size: 3.4rem;
      line-height: 4rem;

      @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
        padding-left: 1.6rem;
        font-size: 2.6rem;
        line-height: 3rem;
      }
    }
    &--actions {
      display: flex;
      align-items: center;
      padding: 2.4rem 3.2rem 2.4rem;

      @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
        padding: .8rem 1.6rem 1.2rem;
      }
    }
    &--themes {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &--title {
        opacity: .5;
        padding: .7rem .8rem .3rem 0;
        margin-right: 1.6rem;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-transform: uppercase;
      }
    }
    .custom-select {
      margin-left: -.8rem;

      &--trigger {
        padding: .7rem .8rem .3rem;
        border: .1rem solid transparent;
        border-bottom: 0 none;
        color: #fff;
        font-size: 1.4rem;
        line-height: 2.4rem;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M16 9l-3.647 3.647L8.707 9 8 9.707l4.353 4.354 4.353-4.354z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23FFF' d='M-269-4H32v32h-301z'/%3E%3Cuse fill='%23FFF' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A"); // chevron-down--small.svg
        }
        &.active {
          border-color: #fff;
        }
      }
      &--dropdown {
        z-index: 40;
        position: absolute;
        width: 100%;
        background-color: #000;
        border: .1rem solid #fff;
        border-top: 0 none;

        &--item {
          display: block;
          width: 100%;
          padding: .7rem .8rem .3rem;
          color: #fff;
          font-size: 1.4rem;
          line-height: 2.4rem;
          text-align: left;

          &:hover,
          &.active {
            background-color: rgba(#fff, .15);
          }
        }
      }
    }
    &--tab-list-container {
      position: relative;
      border-top: .1rem solid rgba($line, .4);
      background-color: #000;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: .1rem;
        background-color: rgba($line, .4);
        content: "";
      }
    }
    &--tab-list {
      padding: 0;
      margin: 0;
      font-size: 0;
      list-style: none;
    }
    &--tab {
      cursor: pointer;
      opacity: .7;
      position: relative;
      display: inline-block;
      padding-top: 2.1rem;
      padding-bottom: 1.3rem;
      border-bottom: .3rem solid transparent;
      margin-right: 2rem;
      font-family: 'Brut Grotesque Regular', sans-serif;
      font-size: 1.2rem;
      line-height: 1.2rem;
      text-transform: uppercase;

      @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
        padding-top: 1.6rem;
        padding-bottom: .9rem;
      }
      &:first-child {
        margin-left: 3.2rem;

        @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
          margin-left: 1.6rem;
        }
      }
      &:hover,
      &--active {
        opacity: 1;
        border-bottom-color: #fff;
        font-family: 'Brut Grotesque Text';
      }
    }
    &--tab-panel {
      display: none;

      &--active {
        display: block;
      }
    }
    &--image-grid {
      display: flex;
      width: auto;
      padding: 3.2rem 3.2rem 0;
      margin-left: -3.2rem;

      @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
        padding: 1.6rem 1.6rem 0;
        margin-left: -1.6rem;
      }
    }
    &--image-grid-column {
      padding-left: 3.2rem;
      background-clip: padding-box;

      @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
        padding-left: 1.6rem;
      }
      .pdf-viewer--downloads--image-item {
        &--overlay {
          justify-content: flex-end;
          padding: .8rem;
        }
        &--btn-select {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &--image-item {
      position: relative;
      background-color: $line;
      margin-bottom: 3.2rem;

      @media (max-width: #{$screen-lg - 1px}) and (max-height: 414px) {
        margin-bottom: 1.6rem;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        @include object-fit(cover, center);
      }
      &--overlay {
        display: flex;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($deepblue, .4);
        transition: opacity .3s ease;
      }
      &:hover {
        .pdf-viewer--downloads--image-item--overlay {
          opacity: 1;
        }
      }
      &--btn-expand,
      &--btn-download {
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        background-color: rgba(#fff, .15);
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: background-color .3s ease;

        &:hover {
          background-color: rgba(#fff, .25);
        }
      }
      &--btn-expand {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M7 12v5h5v1H6v-6h1zm11-6v6h-1l-.001-5H12V6h6z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // (/assets/images/icons/expand.svg)
      }
      &--btn-download {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M16 17v1H9v-1h7zM13 6v5.793l3.146-3.146.707.707-4.354 4.354-4.353-4.354.707-.707L12 11.793V6h1z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // (/assets/images/icons/download-small.svg)
        margin-left: .8rem;
      }
      &--btn-select {
        position: relative;
        min-height: 2.4rem;
        padding-top: .6rem;
        padding-left: 3.2rem;
        color: #fff;
        font-family: 'Brut Grotesque Regular', sans-serif;
        font-size: 1.4rem;

        &::before,
        &::after {
          position: absolute;
          left: 0;
          top: 0;
          width: 2.4rem;
          height: 2.4rem;
        }
        &::before {
          border: .1rem solid #fff;
          border-radius: 50%;
          content: "";
        }
        &.active {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23FFF' d='M18 7l-7.646 7.646L6.708 11 6 11.707l4.354 4.353 8.353-8.354z' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // check--thin.svg
            content: "";
          }
        }
      }
    }
    &--color-list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 1.6rem;

      @include at-most-lg {
        padding: .8rem;
      }
    }
    &--color-item {
      position: relative;
      flex-basis: calc(50% - 3.2rem);
      margin: 1.6rem;

      @include at-most-lg {
        flex-basis: calc(50% - 1.6rem);
        margin: .8rem;
      }
      &::before {
        display: block;
        padding-top: 100%;
        content: "";
      }
      &--content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &-less {
          padding: 2.4rem;

          @include at-most-lg {
            padding: 1.6rem;
          }
        }
        &-more {
          z-index: 1;
        }
      }
      &--table {
        td {
          overflow: hidden;
          max-width: 0;
          padding: 1.6rem .8rem 1.2rem;
          border-top: .1rem solid rgba(#fff, .2);
          color: #fff;
          font-size: 1rem;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:first-child {
            width: 75%;
            padding-left: 1.6rem;
          }
          &:last-child {
            width: 25%;
            padding: 0;
            text-align: center;
          }
          @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1px}) {
            padding: 1rem .8rem .8rem;
          }
        }
      }
      &--th {
        opacity: .5;
        margin-right: .8rem;
        text-transform: uppercase;
      }
      &--copy-btn {
        padding: 1.6rem .8rem 1.1rem;
        color: #fff;
        line-height: 1.3rem;

        @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1px}) {
          padding: 1rem .8rem .8rem;
        }
      }
      &--btn {
        position: absolute;
        top: .8rem;
        right: .8rem;
      }
      &--hex {
        font-family: 'Brut Grotesque Regular', sans-serif;
        font-size: 1.8rem;
        line-height: 1.5rem;
        text-transform: uppercase;
      }
      &--title {
        margin-top: .8rem;
        font-size: 1.4rem;
        line-height: 1.2rem;
      }
      &.light {
        color: $deepblue;

        .pdf-viewer--downloads--color-item--table td,
        .pdf-viewer--downloads--color-item--copy-btn {
          color: $deepblue;
        }
        .pdf-viewer--downloads--color-item--btn {
          &-more {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M12 4v8H4v1h8v8h1v-8h8v-1h-8V4z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // plus--large.svg
          }
          &-less {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M6 12v1h13v-1z'/%3E%3C/defs%3E%3Cuse fill='%231E1B31' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); // minus--large.svg
          }
        }
      }
    }
    &--harmony-list {
      padding: 3.2rem 3.2rem 0;

      @include at-most-lg {
        padding: 1.6rem 1.6rem 0;
      }
      .pdf-viewer--downloads--image-item {
        & + .pdf-viewer--downloads--image-item {
          margin-top: 3.2rem;

          @include at-most-lg {
            margin-top: 1.6rem;
          }
        }
        &--btn-expand {
          margin-left: auto;
        }
        &--overlay {
          align-items: center;
          justify-content: space-between;
          padding-right: 1.6rem;
          padding-left: 2.4rem;
        }
      }
    }
    &--flat-sketches-table {
      td {
        vertical-align: bottom;
        height: 8rem;
        padding: 2.8rem 3.2rem 2.4rem;
        border-bottom: .1rem solid rgba($line, .2);
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.2rem;

        @include at-most-lg {
          padding-right: 1.6rem;
          padding-left: 1.6rem;
        }
      }
      &--file-type {
        margin-bottom: .4rem;
        color: $grey;
        font-size: 1rem;
        text-transform: uppercase;
      }
      &--desc {
        opacity: .5;
      }
      &--btn-download {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M19 20v1H6v-1h13zM13 3v10.793l5.146-5.146.708.707-6.355 6.353-6.353-6.353.707-.707L12 13.793V3h1z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat; // (/assets/images/icons/download.svg)
        background-size: 2.4rem 2.4rem;
      }
    }
    &--img-expand {
      z-index: 50;
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      padding: 4.8rem;

      @include at-most-lg {
        padding: 1.6rem;
      }
      .lazy-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          @include object-fit(contain, center);
        }
      }
      .pdf-viewer--btn-close {
        top: 2.4rem;
        right: 2.4rem;

        @include at-most-lg {
          top: 1.6rem;
          right: 1.6rem;
        }
      }
    }
  }
  &--btn-close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 2.4rem;
    height: 2.4rem;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.021 6l-5.657 5.656L6.706 6 6 6.707l5.656 5.657L6 18.02l.706.708 5.658-5.657 5.657 5.657.706-.708-5.656-5.656 5.656-5.657z'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat;

    &::after { // extend clickable zone (esp. for touch devices)
      position: absolute;
      top: -1.2rem;
      left: -1.2rem;
      width: 4.8rem;
      height: 4.8rem;
      content: "";
    }
  }
  &--btn-download-all {
    position: relative;
    padding-left: 3.6rem;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M16 17v1H9v-1h7zM13 6v5.793l3.146-3.146.707.707-4.354 4.354-4.353-4.354.707-.707L12 11.793V6h1z' id='a'/%3E%3C/defs%3E%3Cuse fill='%23FFF' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E%0A") no-repeat left .8rem center; // (/assets/images/icons/download-small.svg)
    background-size: 2.4rem 2.4rem;
    margin-left: auto;
    white-space: nowrap;
  
    @include at-most-md {
      padding-top: 1.3rem;
      padding-bottom: .7rem;
    }
    &.loading {
      padding-right: 5.6rem;

      .rpv-core__spinner {
        display: block;
      }
    }
    .rpv-core__spinner {
      position: absolute;
      top: .5rem;
      right: 1.6rem;
      display: none;
      width: 2.4rem;
      height: 2.4rem;

      &-circle {
        stroke: #fff;
      }
    }
  }
  &--multi-download {
    &--activated {
      .pdf-viewer--downloads--image-item {
        &--btn-select {
          font-size: 0;
        }
        &--overlay {
          opacity: 1;
        }
        &--btn-download,
        &--btn-expand {
          pointer-events: none;
          opacity: 0;
        }
      }
    }
    &--status {
      position: fixed;
      left: 7vw;
      right: 7vw;
      bottom: -10rem;
      width: 70vw;
      display: flex;
      align-items: center;
      background-color: #000;
      padding: 1.6rem;
      color: #fff;
      font-size: 1.4rem;
      transition: bottom .5s cubic-bezier(.68, -.55, .27, 1.55);

      @include at-most-md {
        position: fixed;
        width: auto;
        left: 1.6rem;
        right: 1.6rem;
      }
      @include at-least-md {
        left: auto;
        width: 42vw;
      }
      @include at-least-lg {
        width: 42vw;
      }
      @include at-least-xl {
        width: 36vw;
      }
      &.show {
        bottom: 2.4rem;

        @include at-most-md {
          bottom: 1.6rem;
        }
      }
      .btn {
        margin-left: auto;
      }
    }
    &--overlay {
      z-index: 1000;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: none;
      background-color: rgba(0, 0, 0, .8);
      transition: all .5s ease;

      &.show {
        display: block;
      }
      .inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rpv-core__spinner {
        width: 4.8rem;
        height: 4.8rem;

        &-circle {
          stroke: #fff;
        }
      }
    }
    &--button {
      position: relative;
      transition: all .3s ease;

      &.loading {
        padding-right: 5.6rem;

        .rpv-core__spinner {
          display: block;
        }
      }
    }
  }
}
